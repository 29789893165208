import { scrollAnimation } from './function/scroll-animation.js';

// ===========================================
// スムーススクロールの関数
// ===========================================
$(function () {
  $('a[href^="#"]').click(function () {
    const speed = 500,
        href = $(this).attr("href"),
        target = $(href == "#" || href == "" ? "html" : href),
        position = target.offset().top;

    $("html, body").animate(
      {
        scrollTop: position,
      },
      speed,
      "swing"
    );
    return false;
  });
});


// ===========================================
// スクロールアニメーションのオプション
// ===========================================
scrollAnimation({
  className: "c-fadeIn",
  triggerPoint: 100,
});

scrollAnimation({
  className: "c-keyVisual",
  triggerPoint: 100,
});


// ===========================================
// ヘッダーのスクロール時のインタラクション
// ===========================================
$(function () {
  let startPos = 0,
    winScrollTop = 0;

  $(window).on("load scroll", function () {
    winScrollTop = $(this).scrollTop();
    if (winScrollTop < startPos || winScrollTop <= 0) {
      $(".t-pageHeader").removeClass("t-pageHeader--hide");
    } else {
      $(".t-pageHeader").addClass("t-pageHeader--hide");
    }

    if (winScrollTop >= 200) {
      $(".t-pageHeader").addClass("t-pageHeader--back");
    } else {
      $(".t-pageHeader--back").removeClass("t-pageHeader--back");
    }

    startPos = winScrollTop;
  });
});


// ===========================================
// ハンバーガーメニュー展開時にスクロールを制限する
// ===========================================
$(function () {
  $("#t-hamburgerCheck").on("change", function () {
    setTimeout(function () {
      if ($("#t-hamburgerCheck").prop("checked") === true) {
        $("html").css({ overflow: "hidden" });
      } else {
        $("html").css({ overflow: "auto" });
      }
    }, 100);
  });

  let widthBefore = $(window).width();

  $(window).on("resize", function () {
    if (widthBefore !== $(window).width()) {
      $("html").css({ overflow: "auto" });
      $("#t-hamburgerCheck").prop("checked", false);
      widthBefore = $(window).width();
    }
  });
});


// ===========================================
// ヘッダーのサブナビ展開時のインタラクション
// ===========================================
$(function () {
  $('.t-pageHeader').each(function(){
    const header = $(this),
          logo = $(this).find('.t-pageHeader__logo img'),
          parentItem = header.find('.t-pageHeaderList__item--parent'),
          parentItemSub = parentItem.find('.t-pageHeaderListSub__item'),
          back = parentItem.find('.t-pageHeaderParentBack'),
          navSub = parentItem.find('.t-pageHeaderNavSub');

    parentItem.hover(
      function() {
        header.addClass('t-pageHeader--subNavOn');
        logo.css({ transitionDelay: 0 + 's' });
        back.css({ transitionDelay: 0 + 's' });
        navSub.css({ transitionDelay: 0 + 's' });

        parentItemSub.each(function( n ){
          $(this).css({ transitionDelay: .4 + ( .05 * (n + 1) ) + 's' });
        });
      },
      function() {
        header.removeClass('t-pageHeader--subNavOn');
        logo.css({ transitionDelay: .6 + 's' });
        back.css({ transitionDelay: .6 + 's' });
        navSub.css({ transitionDelay: .6 + 's' });

        $( parentItemSub.get().reverse() ).each(function( n ){
          $(this).css({ transitionDelay: .1 + ( .05 * (n + 1) ) + 's' });
        });
      }
    );
  });
});


// ===========================================
// ハンバーガーメニュー展開時のディレイ
// ===========================================
$(function () {
  $('.t-pageHeader').each(function(){
    const hamburger = $('#t-hamburgerCheck'),
          logo = $(this).find('.t-pageHeader__logo img'),
          item = $(this).find('.t-pageHeaderList__item'),
          cvBtn = $(this).find('.t-pageHeaderCv'),
          back = $(this).find('.t-headerBackMobile');

    hamburger.on('change', function(){
      let btnDelay = 0;

      if( hamburger.prop('checked') === true ) {
        logo.css({ transitionDelay: 0 + 's' });
        back.css({ transitionDelay: 0 + 's' });

        item.each(function( n ){
          $(this).css({ transitionDelay: .4 + ( .05 * (n + 1) ) + 's' });
          btnDelay = .4 + ( .05 * (n + 1) );
        });

        cvBtn.css({ transitionDelay: btnDelay + 's' });
      } else {
        logo.css({ transitionDelay: .6 + 's' });
        back.css({ transitionDelay: .6 + 's' });

        $( item.get().reverse() ).each(function( n ){
          $(this).css({ transitionDelay: .1 + ( .05 * (n + 1) ) + 's' });
          btnDelay = .1 + ( .05 * (n + 1) );

          cvBtn.css({ transitionDelay: 0 + 's' });
        });
      }
    });

    $(window).on('resize', function(){
      item.each(function( n ){
        $(this).css({ transitionDelay: 0 + 's' });
      });
    });
  });
});


// ===========================================
// モバイルでのヘッダーのサブナビのアコーディオン
// ===========================================
$(function () {
  $('.t-pageHeaderList__item--parent').each(function(){
    const btn = $(this),
          box = $(this).find('.t-pageHeaderNavSub');
    let beforeWidth = $(window).width();

    function defaultSet( box ) {
      if( $(window).width() < 1300 ) {
        box.css({ display: 'none' });
      } else {
        box.css({ display: 'block' });
      }
    }
    defaultSet( box );

    $(window).on('resize', function(){
      if( beforeWidth !== $(window).width() ) {
        defaultSet( box );
        btn.removeClass('open');
      }

      beforeWidth = $(window).width();
    });

    btn.on('click', function(){
      if( $(window).width() < 1300 ) {
        box.slideToggle(300);
        btn.toggleClass('open');
      }
    });
  });
});


// ===========================================
// ヘッダーのスクロール時のインタラクション
// ===========================================
$(function () {
  let scroll,
      absolutePoint;

  function absoluteJudge( bg, area ) {
    scroll = $(window).scrollTop(),
    absolutePoint = area.offset().top + area.outerHeight() - bg.outerHeight();

    if( scroll > absolutePoint ) {
      bg.addClass('absolute');
    } else {
      bg.removeClass('absolute');
    }
  }

  $('.c-fixedScrollAreaWrapper').each(function(){
    const wrapper = $(this);

    if(
      wrapper.find('.c-bgFixed').length &&
      wrapper.find('.c-fixedScrollArea').length
    ) {
      const bg = wrapper.find('.c-bgFixed'),
            area = wrapper.find('.c-fixedScrollArea');

      absoluteJudge( bg, area );

      $(window).on('scroll', function(){
        absoluteJudge( bg, area );
      });
    }
  });
});