import { scrollAnimation } from './function/scroll-animation.js';
const tile = require('./function/tile.js');
const slick = require('/assets/js/slick/slick.js');
import '/assets/js/common.js';
import '/assets/js/slick/slick.css';
import '/assets/css/_common.scss';
import '/assets/css/top.scss';


// ===========================================
// 訪問時のメインビジュアルのアニメーション
// ===========================================
$(function () {
  $('.mainVisual').each(function(){
    const img = $(this).find('.mainVisual__img:nth-child(1)'),
          ttl = $(this).find('.mvHead__ttl'),
          txt = $(this).find('.mvHead__txt');

    img.addClass('fadeIn');

    setTimeout(function () {
      ttl.addClass('slideIn');
    }, 1000);

    setTimeout(function () {
      txt.addClass('slideIn');
    }, 1150);
  });
});


// ===========================================
// メインビジュアルのスライドショー
// ===========================================
$(function () {
  function slideLoop( count, slide ) {
    slide
      .find('.mainVisual__img.fadeIn')
      .removeClass('fadeIn')
      .removeClass('zoomIn');

    slide
      .find('.mainVisual__img:nth-child(' + count + ')')
      .addClass('fadeIn');

    setTimeout(function () {
      slide
        .find('.mainVisual__img:nth-child(' + count + ')')
        .addClass('zoomIn');
    }, 1000);

    setTimeout(function () {
      count++;
      if( count > 4 ) {
        count = 1;
      }
      slideLoop( count, slide );
    }, 8000);
  }

  $('.mainVisual').each(function(){
    let count = 1;

    slideLoop( count, $(this) );
  });
});


// ===========================================
// お知らせ一覧のレイアウト
// ===========================================
$(function () {
  $('.newsListWrapper').each(function(){
    $(this).find('.c-newsList').tile();
  });
});


// ===========================================
// ミッションエリアのフェードイン
// ===========================================
scrollAnimation({
  className: "mission",
  triggerPoint: 100,
});


// ===========================================
// パララックス
// ===========================================
$(function () {
  // パララックスの処理の関数
  function parallaxExecution( parallaxItem, rate ) {
    const position = parallaxItem.offset().top,
          scroll = $(window).scrollTop(),
          windowHeight = $(window).height();

    if ( $(window).width() > 1024 ) {
      let parallaxValue = ( ( (scroll + windowHeight - position) / 5 ) - ( windowHeight / 10 ) ) / rate;

      parallaxItem.css({
        transform: "translateY(" + -1 * parallaxValue + "px)"
      });
    } else {
      parallaxItem.attr('style', '');
    }
  }

  // パララックスの処理を実行する関数
  function parallax( parallaxObject, rate ) {
    parallaxExecution( parallaxObject, rate );

    $(window).on('scroll', function(){
      parallaxExecution( parallaxObject, rate );
    });
  }

  // パララックスの処理を実行
  parallax( $('.missionTmb1Inner'), 1.8 );
  parallax( $('.missionTmb2Inner'), 1.8 );
  parallax( $('.missionDesign1'), 1.3 );
  parallax( $('.missionDesign2'), 1.3 );

  parallax( $('.productTmbInner'), 1.8 );
});


// ===========================================
// テキストスライダー
// ===========================================
$(function () {
  let time,
      beforeWidth = $(window).width();

  function txtSlide( txt, timeRate ) {
    time = Math.floor( txt.width() * timeRate );

    txt.slick({
      autoplay: true,
      autoplaySpeed: 0,
      arrows: false,
      slidesToShow: 1,
      cssEase: 'linear',
      pauseOnFocus: false,
      pauseOnHover: false,
      speed: time,
    });
  }

  function txtSlideSet( txt, timeRate ) {
    txtSlide( txt, timeRate );

    $(window).on('resize', function(){
      if( beforeWidth !== $(window).width() ) {
        txt.slick('unslick');
        txtSlide( txt, timeRate );
        beforeWidth = $(window).width();
      }
    });
  }

  $('.missionTextSlide').each(function(){
    const txt = $(this);

    txtSlideSet( txt, 10 );
  });

  $('.recruitTxtSlide').each(function(){
    const txt = $(this);

    txtSlideSet( txt, 20 );
  });

});


// ===========================================
// プロダクトエリアのフェードイン
// ===========================================
scrollAnimation({
  className: "product",
  triggerPoint: 100,
});


// ===========================================
// リクルートエリアのフェードイン
// ===========================================
scrollAnimation({
  className: "recruit",
  triggerPoint: 100,
});


// ===========================================
// リクルートエリアのアイコンのレイアウト
// ===========================================
$(function () {
  $('.recruitBnr__icon').each(function(){
    const icon = $(this),
          iconHtml = icon.html();

    $(window).on('resize', function(){
      icon.html('');
      icon.html( iconHtml );
    });
  });
});